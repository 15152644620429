footer a {
    text-decoration: none;
    position: relative;
  }
  
  footer a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 100%;
    background: white;
    transition: transform 0.3s ease;
    transform: scaleX(0);
    transform-origin: bottom right;
  }
  
  footer a:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }