@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.kosugi-maru-regular {
  font-family: "Kosugi Maru", sans-serif;
  font-weight: 400;
  font-style: normal;
}
